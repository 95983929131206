var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"resumo-cargas-bdgd-data-table",attrs:{"headers":[
      {
        text: 'BDGD',
        value: 'data_registro'
      },
      {
        text: 'Database',
        value: 'database'
      },
      {
        text: 'Nº de cargas',
        value: 'numero_cargas'
      },
      {
        text: 'Última carga em',
        value: 'data_ultima_carga'
      }
    ],"items":_vm.items,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":true,"no-data-text":"Nenhuma carga de BDGD realizada no servidor"},scopedSlots:_vm._u([{key:"item.data_registro",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.numero_cargas",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":"/perdas-tecnicas/carregar-bdgd/historico"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_cargas))+" ")])]}},{key:"item.data_ultima_carga",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.data_ultima_carga))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }