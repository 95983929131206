<template>
  <v-container fluid>
    <v-data-table
      class="resumo-cargas-bdgd-data-table"
      :headers="[
        {
          text: 'BDGD',
          value: 'data_registro'
        },
        {
          text: 'Database',
          value: 'database'
        },
        {
          text: 'Nº de cargas',
          value: 'numero_cargas'
        },
        {
          text: 'Última carga em',
          value: 'data_ultima_carga'
        }
      ]"
      :items="items"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="true"
      no-data-text="Nenhuma carga de BDGD realizada no servidor"
    >
      <template v-slot:[`item.data_registro`]="{ item }">
        {{ item.data_registro | formatToMonth }} v{{ item.versao }}
      </template>
      <template v-slot:[`item.numero_cargas`]="{ item }">
        <router-link to="/perdas-tecnicas/carregar-bdgd/historico">
          {{ item.numero_cargas | parseNumberToIntegerBR }}
        </router-link>
      </template>
      <template v-slot:[`item.data_ultima_carga`]="{ item }">
        {{ item.data_ultima_carga | parseToDateTimeBR }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CargasBdgdsService from '@/services/CargasBdgdsService';

export default {
  name: 'ResumoCargasBdgdsServidorDataTable',
  props: {
    servidor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    items: [],
    loading: false
  }),
  mounted() {
    this.getResumoCargasBdgds();
  },
  methods: {
    getResumoCargasBdgds() {
      if (!this.servidor) return;
      this.loading = true;
      CargasBdgdsService.getResumoCargasBdgdsServidor(this.servidor.id)
        .then((response) => (this.items = response.data))
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    servidor() {
      this.getResumoCargasBdgds();
    }
  }
};
</script>
