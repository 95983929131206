<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <general-progress-bars
        class="mt-5 mb-5"
        :items="downloadProgressData"
        prefixText="Baixando ..."
      />
      <v-alert
        dense
        outlined
        type="info"
        color="info"
      >
        <span>
          Classificações do campo COD_BNC das entidades
          {{ entidades.join(', ') }} no servidor.
        </span>
        <ul>
          <li
            v-for="entidade in entidades"
            :key="entidade"
          >
            <!-- prettier-ignore -->
            <span style="cursor: pointer;" @click="() => baixarLinhas(entidade)">
              {{ entidade.toUpperCase() }}
            </span>
          </li>
        </ul>
      </v-alert>
      <v-alert
        dense
        outlined
        color="deep-orange"
        icon="mdi-alert"
        v-if="totalLinhasCamposCodBncNulos"
      >
        <span>
          Entidades com os campos COD_BNC nulos no servidor. Clique na entidade
          abaixo para baixar as linhas:
        </span>
        <ul>
          <li
            v-for="(totalNulos, entidade) in entidadesComCodBncNulos"
            :key="entidade"
          >
            <!-- prettier-ignore -->
            <span style="cursor: pointer;" @click="() => baixarLinhas(entidade)">
              {{ entidade.toUpperCase() }} ({{ totalNulos | parseNumberToIntegerBR }})
            </span>
          </li>
        </ul>
      </v-alert>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';
import GeneralProgressBars from '@/components/general/GeneralProgressBars.vue';

export default {
  name: 'AlertaEntidadesCodBncNulos',
  components: {
    GeneralProgressBars
  },
  props: {
    servidor: {
      type: Object,
      required: true
    },
    database: {
      type: String
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    entidades: ['EQRE', 'EQTRAT', 'EQTRMT'],
    statusCamposCodBncEntidades: {},
    downloadProgressData: {}
  }),
  mounted() {
    this.getStatusCamposCodBncEntidades();
  },
  computed: {
    entidadesComCodBncNulos() {
      const entidades = Object.keys(this.statusCamposCodBncEntidades).filter(
        (entidade) =>
          Number(
            this.statusCamposCodBncEntidades[entidade][
              'num_linhas_cod_bnc_nulos'
            ]
          ) > 0
      );
      const entidadesComCodBncNulos = {};
      entidades.forEach((entidade) => {
        const numLinhas =
          this.statusCamposCodBncEntidades[entidade][
            'num_linhas_cod_bnc_nulos'
          ];
        entidadesComCodBncNulos[entidade] = Number(numLinhas);
      });
      return entidadesComCodBncNulos;
    },
    totalLinhasCamposCodBncNulos() {
      return Object.values(this.entidadesComCodBncNulos).reduce(
        (total, valor) => total + Number(valor),
        0
      );
    },
    mesBdgd() {
      return dayjs(this.bdgdVersao.data_registro).format('YYYY-MM');
    }
  },
  methods: {
    getStatusCamposCodBncEntidades() {
      if (!(this.servidor && this.database && this.bdgdVersao)) {
        return;
      }
      this.loading = true;
      ServidoresBancosDadosGeoPerdasService.getStatusCamposCodBncEntidades(
        this.servidor.id,
        this.database,
        this.bdgdVersao.id,
        this.entidades
      )
        .then(({ data }) => (this.statusCamposCodBncEntidades = data))
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error(
            'Erro ao buscar o status de preenchimento dos campos COD_BNC no servidor.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    },
    baixarLinhas(entidade) {
      const id = dayjs(new Date()).format('YYYYMMDDHHmm');
      let nomeArquivo = `${entidade.toLowerCase()}-linhas-cod-bnc-`;
      nomeArquivo += `mes-${this.mesBdgd}-versao-${this.bdgdVersao.versao}-${id}.xlsx`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      ServidoresBancosDadosGeoPerdasService.baixarLinhasEntidadeComCodBncNulos(
        this.servidor.id,
        this.database,
        this.bdgdVersao.id,
        entidade.toLowerCase(),
        {
          timeout: 60 * 60 * 1000,
          onDownloadProgress: (progressEvent) => {
            let progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            let isComputable = progressEvent.lengthComputable;
            this.$set(this.downloadProgressData, id, {
              progress,
              isComputable,
              id
            });
          }
        }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(
            `Erro ao baixar o arquivo com as linhas de COD_BNC nulas na entidade ${entidade.toUpperCase()}.`,
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  },
  watch: {
    servidor() {
      this.getStatusCamposCodBncEntidades();
    },
    bdgdVersao() {
      this.getStatusCamposCodBncEntidades();
    },
    database() {
      this.getStatusCamposCodBncEntidades();
    },
    loading() {
      this.$emit('loadingStatusCamposCodBnc:changed', this.loading);
    },
    totalLinhasCamposCodBncNulos() {
      this.$emit(
        'totalLinhasCamposCodBncNulos:changed',
        this.totalLinhasCamposCodBncNulos
      );
    }
  }
};
</script>
