<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-table-sync"
            inline
            class="px-5 py-3"
            style="min-height: 800px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Executar Etapa 17 NVAL (Procedure "Principal")
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <select-server
                  :type="'outlined'"
                  @serverSelected="(servidor) => (this.servidor = servidor)"
                />
              </v-col>
            </v-row>
            <v-row v-if="servidor && conectadoServidor">
              <v-col cols="12">
                <select-database
                  :type="'outlined'"
                  :onlyDatabaseWithPrefix="databasePrefix"
                  :selectedServer="servidor"
                  @databaseSelected="(database) => (this.database = database)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-all-bdgd-versions
                  label="Versão da BDGD"
                  type="outlined"
                  :showIcon="false"
                  :companyId="companyId"
                  @bdgdVersionSelected="
                    (bdgdVersao) => (this.bdgdVersao = bdgdVersao)
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="servidor && conectadoServidor">
              <v-col cols="12">
                <div class="display-1 font-weight-light">
                  Resumo de cargas realizadas no servidor:
                </div>
                <v-divider class="mt-2 mb-5" />
                <resumo-cargas-bdgds-servidor-data-table :servidor="servidor" />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-table-multiple"
            inline
            class="px-5 py-3"
            style="min-height: 800px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Entidades da BDGD carregadas no servidor
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <status-conexao-servidor
                  v-if="servidor"
                  :servidor="servidor"
                  @conectadoServidor:changed="
                    ($event) => (conectadoServidor = $event)
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="conectadoServidor && this.bdgdVersao">
              <v-col cols="12">
                <alerta-entidades-cod-bnc-nulos
                  :servidor="servidor"
                  :database="database"
                  :bdgdVersao="bdgdVersao"
                  @loadingStatusCamposCodBnc:changed="
                    ($event) => (loadingStatusCamposCodBnc = $event)
                  "
                  @totalLinhasCamposCodBncNulos:changed="
                    ($event) => (totalLinhasCamposCodBncNulos = $event)
                  "
                />
              </v-col>
            </v-row>
            <v-row v-if="conectadoServidor">
              <v-col cols="12">
                <de-para-bdgd-importada-servidor-data-table
                  ref="deParaBdgd"
                  :servidor="servidor"
                  :database="database"
                  :bdgdVersao="bdgdVersao"
                  @bdgdsDiferentes:changed="
                    ($event) => (bdgdsDiferentes = $event)
                  "
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="executarProcedure()"
            :disabled="!podeExecutarProcedure"
            :loading="loading"
          >
            Executar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ExecucoesProcedurePrincipalGeoPerdasService from '@/services/ExecucoesProcedurePrincipalGeoPerdasService';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions.vue';
import SelectServer from '@/components/geo-perdas/servidores-bancos-dados/SelectServer.vue';
import SelectDatabase from '@/components/geo-perdas/servidores-bancos-dados/SelectDatabase.vue';
import ResumoCargasBdgdsServidorDataTable from '@/components/geo-perdas/servidores-bancos-dados/ResumoCargasBdgdsServidorDataTable.vue';
import DeParaBdgdImportadaServidorDataTable from '@/components/geo-perdas/servidores-bancos-dados/DeParaBdgdImportadaServidorDataTable.vue';
import AlertaEntidadesCodBncNulos from '@/components/geo-perdas/servidores-bancos-dados/AlertaEntidadesCodBncNulos.vue';
import StatusConexaoServidor from '@/components/geo-perdas/servidores-bancos-dados/StatusConexaoServidor.vue';
import routes from '@/store/modules/routes';

export default {
  components: {
    SelectAllBdgdVersions,
    SelectServer,
    SelectDatabase,
    ResumoCargasBdgdsServidorDataTable,
    DeParaBdgdImportadaServidorDataTable,
    AlertaEntidadesCodBncNulos,
    StatusConexaoServidor
  },
  data: () => ({
    servidor: null,
    bdgdVersao: null,
    database: null,
    conectadoServidor: false,
    loading: false,
    loadingStatusCamposCodBnc: false,
    totalLinhasCamposCodBncNulos: false,
    bdgdsDiferentes: true
  }),
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    databasePrefix() {
      return `GEO_SIGR_${this.userLoggedCompany.acronym}`;
    },
    podeExecutarProcedure() {
      return !!(
        this.servidor &&
        this.conectadoServidor &&
        this.bdgdVersao &&
        this.database &&
        !this.loadingStatusCamposCodBnc &&
        this.totalLinhasCamposCodBncNulos < 1
      );
    }
  },
  methods: {
    executarProcedure() {
      if (
        this.bdgdsDiferentes &&
        !confirm(
          'Atenção: parece que a BDGD selecionada é diferente ou não foi completamente carregada no servidor. Deseja executar a procedure?'
        )
      ) {
        return false;
      }
      const { numeroLinhasServidorGeoPerdas } = this.$refs.deParaBdgd;
      this.loading = true;
      const data = {
        numero_linhas: numeroLinhasServidorGeoPerdas,
        bdgd_versao_id: this.bdgdVersao.id,
        servidor_id: this.servidor.id,
        database: this.database
      };
      ExecucoesProcedurePrincipalGeoPerdasService.save(data)
        .then(() => {
          this.$toast.success(
            'Execução da procedure agendada com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$router.replace({
            name: routes.state.appRoutes[
              'PREENCHER_TABELAS_OPERACIONAIS_HISTORICO'
            ]
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao agendar a execução da procedure.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
