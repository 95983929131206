<template>
  <div>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-alert
          dense
          outlined
          color="deep-orange"
          icon="mdi-alert"
          v-if="bdgdsDiferentes"
        >
          <span>
            Atenção: parece que a BDGD selecionada é diferente ou não foi
            completamente carregada no servidor.
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto mb-5 break-search"
          label="Procurar"
          hide-details
          single-line
          v-if="!loading"
        />
        <v-data-table
          class="de-para-bdgd-data-table"
          :headers="headers"
          :search.sync="search"
          :items="items"
          :items-per-page="5"
          :mobile-breakpoint="800"
          :loading="loading"
          :hide-default-header="loading"
          :hide-default-footer="loading"
          no-data-text="Nenhuma entidade encontrada"
        >
          <template v-slot:[`item.entidade`]="{ item }">
            <v-icon
              small
              color="green"
              class="pb-1 icon-status"
              v-if="item.numero_linhas_iguais"
            >
              mdi-check-bold
            </v-icon>
            <v-icon
              small
              color="red"
              class="pb-1 icon-status"
              v-else
            >
              mdi-alert
            </v-icon>
            <span :class="getClasses(item)">
              {{ item.entidade | toUpperCase }}
            </span>
          </template>
          <template v-slot:[`item.numero_linhas_bdgd_local`]="{ item }">
            <span :class="getClasses(item)">
              {{ item.numero_linhas_bdgd_local | parseNumberToIntegerBR }}
            </span>
          </template>
          <template v-slot:[`item.numero_linhas_servidor_geoperdas`]="{ item }">
            <span :class="getClasses(item)">
              {{
                item.numero_linhas_servidor_geoperdas | parseNumberToIntegerBR
              }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  name: 'DeParaBdgdImportadaServidorDataTable',
  mixins: [refreshDataMixins],
  props: {
    servidor: {
      type: Object,
      required: true
    },
    database: {
      type: String
    },
    bdgdVersao: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    search: null,
    resumoBdgdLocal: [],
    resumoBdgdRemota: [],
    loadingResumoBdgdServidorLocal: false,
    loadingResumoBdgdServidorRemoto: false
  }),
  mounted() {
    this.getResumoBdgdServidorLocal();
    this.refreshData(this.getResumoBdgdServidorRemoto);
  },
  computed: {
    headers() {
      return [
        {
          text: 'Entidade',
          value: 'entidade'
        },
        {
          text: this.headerBdgdLocal,
          value: 'numero_linhas_bdgd_local'
        },
        {
          text: this.headerBdgdRemota,
          value: 'numero_linhas_servidor_geoperdas'
        }
      ];
    },
    headerBdgdLocal() {
      const mes = dayjs(this.bdgdVersao.data_registro).format('MM/YYYY');
      const versao = this.bdgdVersao.versao;
      return `BDGD Local :: ${mes} v${versao}`;
    },
    headerBdgdRemota() {
      return `BDGD Servidor :: ${this.database}`;
    },
    loading() {
      return (
        this.loadingResumoBdgdServidorLocal ||
        this.loadingResumoBdgdServidorRemoto
      );
    },
    items() {
      return this.resumoBdgdLocal.map(({ entidade, registros }) => {
        const linhasEntidade = [...this.resumoBdgdRemota].filter(
          (item) => item.entidade === entidade
        );
        const obj = {
          entidade,
          numero_linhas_bdgd_local: registros,
          numero_linhas_servidor_geoperdas:
            linhasEntidade[0] && linhasEntidade[0].registros
              ? Number(linhasEntidade[0].registros)
              : 0
        };
        return {
          ...obj,
          numero_linhas_iguais: this.comparaNumeros(
            obj.numero_linhas_bdgd_local,
            obj.numero_linhas_servidor_geoperdas
          )
        };
      });
    },
    bdgdsDiferentes() {
      return this.items.filter((item) => !item.numero_linhas_iguais).length > 0;
    },
    numeroLinhasServidorGeoPerdas() {
      return this.items.reduce(
        (acumulador, item) =>
          (acumulador += Number(item.numero_linhas_servidor_geoperdas)),
        0
      );
    }
  },
  methods: {
    getResumoBdgdServidorLocal() {
      if (!this.bdgdVersao) return;
      this.loadingResumoBdgdServidorLocal = true;
      const dataRegistro = dayjs(this.bdgdVersao.data_registro).format(
        'YYYY-MM-DD'
      );
      ExportacoesBdgdsService.getImportedEntitiesAndRegistersByDate(
        dataRegistro,
        this.bdgdVersao.company_id,
        this.bdgdVersao.id
      )
        .then((response) => (this.resumoBdgdLocal = response.data))
        .finally(() => (this.loadingResumoBdgdServidorLocal = false));
    },
    getResumoBdgdServidorRemoto() {
      if (!this.servidor || !this.database) return;
      this.loadingResumoBdgdServidorRemoto = true;
      ServidoresBancosDadosGeoPerdasService.getResumoEntidadesImportadas(
        this.servidor.id,
        this.database
      )
        .then((response) => (this.resumoBdgdRemota = response.data))
        .finally(() => (this.loadingResumoBdgdServidorRemoto = false));
    },
    getClasses(item) {
      return {
        iguais: item.numero_linhas_iguais,
        diferentes: !item.numero_linhas_iguais
      };
    },
    comparaNumeros(num1, num2) {
      return num1 === num2;
    }
  },
  watch: {
    servidor() {
      this.getResumoBdgdServidorRemoto();
    },
    bdgdVersao: {
      handler() {
        this.getResumoBdgdServidorLocal();
      },
      deep: true
    },
    database() {
      this.getResumoBdgdServidorRemoto();
    },
    bdgdsDiferentes() {
      this.$emit('bdgdsDiferentes:changed', this.bdgdsDiferentes);
    }
  }
};
</script>

<style scoped>
.iguais {
  color: green;
}
.diferentes {
  color: red;
}
.icon-status {
  margin-right: 1.7px !important;
}
</style>
